<template>
  <div id="map" />
</template>

<script>
import { mapGetters } from "vuex";
import MarkerClusterer from "@google/markerclustererplus";
import { createPin } from "./Map/pin";
import { createSpiderfier } from "./Map/spiderfier";

const clustererOptions = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  gridSize: 10,
  maxZoom: 15
};

const mapOptions = {
  zoom: 3,
  maxZoom: 17,
  center: {
    lat: -28.024,
    lng: 140.887
  }
};

const pinUrl =
  "https://firebasestorage.googleapis.com/v0/b/mapsforall-96ddd.appspot.com/o/images%2Fpins%2F" +
  "transparent-pin-no-border.png?alt=media&token=e5769cf5-15cd-4073-93d8-014349368f7a";

export default {
  name: "EditorMap",
  props: {
    selectId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      map: null,
      markerClusterer: null,
      spiderfier: null,
      pin: null,
      pinMap: {}
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  watch: {
    async currMap() {
      if (this.map != null) {
        await this.populateMap();
      }
    },
    selectId() {
      if (this.selectId !== "") {
        const marker = this.currMap.markers.find(
          marker => marker.id === this.selectId
        );
        this.map.panTo({
          lat: parseFloat(marker.location.lat),
          lng: parseFloat(marker.location.long)
        });
        this.map.setZoom(clustererOptions.maxZoom);
      }
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      /* global google */
      this.map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      this.map.addListener("click", marker => {
        this.$emit(
          "onAdd",
          marker.latLng.lat().toString(),
          marker.latLng.lng().toString()
        );
      });

      this.spiderfier = createSpiderfier(this.map);

      let isPinCreated = false;
      if (this.currMap.pin.length > 0) {
        this.pin = await createPin(this.currMap.pin);
        if (this.pin && this.pin.anchor && this.pin.anchor.x !== 0) {
          isPinCreated = true;
        }
      }
      if (isPinCreated === false) {
        this.pin = {
          url: pinUrl,
          anchor: new google.maps.Point(12, 29),
          scaledSize: new google.maps.Size(24, 29)
        };
      }

      await this.populateMap();
    });
  },
  methods: {
    async populateMap() {
      await this.populatePinMap();
      let bounds = new google.maps.LatLngBounds();

      const gmarkers = this.currMap.markers.map(marker => {
        const gmarker = this.createGmarker(marker);

        bounds.extend(gmarker.getPosition());
        gmarker.addListener("click", () => this.$emit("onEdit", marker.id));

        return gmarker;
      });

      if (this.markerClusterer != null) {
        this.markerClusterer.clearMarkers();
      }

      this.markerClusterer = new MarkerClusterer(
        this.map,
        gmarkers,
        clustererOptions
      );

      this.spiderfier.clearMarkers();
      gmarkers.forEach(marker => this.spiderfier.addMarker(marker, () => {}));

      this.map.fitBounds(bounds);
    },
    createGmarker(marker) {
      const pin = Object.prototype.hasOwnProperty.call(this.pinMap, marker.id)
        ? this.pinMap[marker.id]
        : this.pin;
      return new google.maps.Marker({
        position: {
          lat: parseFloat(marker.location.lat),
          lng: parseFloat(marker.location.long)
        },
        title: marker.name,
        icon: pin
      });
    },
    async populatePinMap() {
      for (const marker of this.currMap.markers) {
        if (marker.pin) {
          if (!Object.prototype.hasOwnProperty.call(this.pinMap, marker.id)) {
            this.$set(this.pinMap, marker.id, await createPin(marker.pin));
          }
        }
      }
    }
  }
};
</script>

<style>
#map {
  height: 400px;
}
</style>
