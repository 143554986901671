<template>
  <v-menu :nudge-width="100" offset-y transition="slide-y-transition">
    <template v-slot:activator="{ on: vmenu }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...vmenu }" text>
            <i class="fa fa-cloud-download" />
            <span class="hidden-sm-and-down">
              &nbsp;Export
            </span>
          </v-btn>
        </template>
        <span>Export Map Markers To File</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item href="#">
        <v-list-item-title
          v-download-data="exportData"
          v-download-data:type="'json'"
          >JSON Format</v-list-item-title
        >
      </v-list-item>
      <v-list-item href="#">
        <v-list-item-title
          v-download-data="exportDataText"
          v-download-data:type="'csv'"
          >CSV Format</v-list-item-title
        >
      </v-list-item>
      <v-list-item href="#">
        <v-list-item-title
          v-download-data="exportDataText"
          v-download-data:type="'tsv'"
          >TSV Format</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ExportMenu",
  computed: {
    ...mapGetters(["currMap"]),
    exportData() {
      return this.currMap.markers.map(marker => {
        let exportMarker = Object.assign({}, marker);
        delete exportMarker.id;
        return exportMarker;
      });
    },
    exportDataText() {
      return (
        // do we still get id, action?
        this.currMap.markers.map(marker => {
          let exportMarker = Object.assign({}, marker);
          delete exportMarker.id;
          return this.flatten("", exportMarker, {});
        })
      );
    }
  },
  methods: {
    flatten(root, object, baseobject) {
      for (const property in object) {
        const propertyName = root === "" ? property : `${root}.${property}`;

        if (typeof object[property] === "object") {
          baseobject = this.flatten(propertyName, object[property], baseobject);
        } else {
          baseobject[propertyName] = object[property];
        }
      }

      return baseobject;
    }
  }
};
</script>
