<template>
  <v-card>
    <v-card-title>
      <h3 class="headline logo_text">
        <img v-if="currMap.pin.length > 0" :src="currMap.pin" height="42" />
        <i v-else class="fa fa-map-marker logo_icon" />

        &nbsp;Markers
      </h3>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click.prevent="$emit('onAdd')" text smal>
            <i class="fa fa-plus" />
            <span class="hidden-sm-and-down">
              &nbsp;Add Marker
            </span>
          </v-btn>
        </template>
        <span>Add New Marker</span>
      </v-tooltip>
      <export-menu />
      <import-modal />
      <v-spacer />
      <v-text-field
        v-model="searchQuery"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :header-props="{ 'sort-icon': 'fa-arrow-up' }"
        :headers="columns"
        :items="currMap.markers"
        :items-per-page="20"
        :search="searchQuery"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.prevent="$emit('onEdit', item.id)" icon>
                <i class="fa fa-pencil-square-o" />
              </v-btn>
            </template>
            <span>Edit Marker</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.prevent="$emit('onCopy', item.id)" icon>
                <i class="fa fa-copy" />
              </v-btn>
            </template>
            <span>Copy Marker</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.prevent="$emit('onDelete', item.id)" icon>
                <i class="fa fa-trash-o" />
              </v-btn>
            </template>
            <span>Delete Marker</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click.prevent="$emit('onSelect', item.id)" icon>
                <i class="fa fa-crosshairs crosshairs" />
              </v-btn>
            </template>
            <span>Select Marker On Map</span>
          </v-tooltip>
        </template>
        <template v-slot:item.location="{ item }">
          {{ getDisplayLocation(item.location) }}
        </template>
        <template v-slot:no-data>
          You have not added any markers yet!
          <v-btn @click.prevent="$emit('onAdd')">
            <i class="fa fa-plus" />
            &nbsp;Add Marker
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { MarkerType } from "@/store/mapService";
const importModal = () =>
  import(/* webpackChunkName: "importModal" */ "../ImportModal.vue");
import exportMenu from "../ExportMenu.vue";

export default {
  name: "EditorTable",
  components: {
    importModal,
    exportMenu
  },
  data() {
    return {
      tableProps: {
        xlargeColumns: [
          { text: "Name/Description", value: "name" },
          { text: "Start Date", value: "data.range.start" },
          { text: "End Date", value: "data.range.end" },
          { text: "Value", value: "data.value" },
          { text: "City", value: "location.city" },
          { text: "State", value: "location.state" },
          { text: "Country", value: "location.country" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        largeColumns: [
          { text: "Name/Description", value: "name" },
          { text: "Start Date", value: "data.range.start" },
          { text: "End Date", value: "data.range.end" },
          { text: "Value", value: "data.value" },
          { text: "Location", value: "location" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        mediumColumns: [
          { text: "Name/Description", value: "name" },
          { text: "Location", value: "location" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        smallColumns: [
          { text: "Name/Description", value: "name" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        xlargeColumnsNum: [
          { text: "Name/Description", value: "name" },
          { text: "Minimum Value", value: "data.range.start" },
          { text: "Maximum Value", value: "data.range.end" },
          { text: "Value", value: "data.value" },
          { text: "City", value: "location.city" },
          { text: "State", value: "location.state" },
          { text: "Country", value: "location.country" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        largeColumnsNum: [
          { text: "Name/Description", value: "name" },
          { text: "Minimum Value", value: "data.range.start" },
          { text: "Maximum Value", value: "data.range.end" },
          { text: "Value", value: "data.value" },
          { text: "Location", value: "location" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        mediumColumnsNum: [
          { text: "Name/Description", value: "name" },
          { text: "Location", value: "location" },
          { text: "Actions", value: "actions", sortable: false }
        ],
        smallColumnsNum: [
          { text: "Name/Description", value: "name" },
          { text: "Actions", value: "actions", sortable: false }
        ]
      },
      searchQuery: ""
    };
  },
  computed: {
    ...mapGetters(["currMap"]),
    columns() {
      switch (this.$mq) {
        case "sm":
          if (this.currMap.markerType !== MarkerType.number) {
            return this.tableProps.smallColumns;
          } else {
            return this.tableProps.smallColumnsNum;
          }
        case "md":
          if (this.currMap.markerType !== MarkerType.number) {
            return this.tableProps.mediumColumns;
          } else {
            return this.tableProps.mediumColumnsNum;
          }
        case "lg":
          if (this.currMap.markerType !== MarkerType.number) {
            return this.tableProps.largeColumns;
          } else {
            return this.tableProps.largeColumnsNum;
          }
        default:
          if (this.currMap.markerType !== MarkerType.number) {
            return this.tableProps.xlargeColumns;
          } else {
            return this.tableProps.xlargeColumnsNum;
          }
      }
    }
  },
  methods: {
    getDisplayLocation(location) {
      let displayLocation = "";

      if (location.country.length > 0) {
        displayLocation = location.country;
      }

      if (location.state.length > 0) {
        displayLocation =
          displayLocation.length > 0
            ? `${location.state}, ${displayLocation}`
            : location.state;
      }

      if (location.city.length > 0) {
        displayLocation =
          displayLocation.length > 0
            ? `${location.city}, ${displayLocation}`
            : location.city;
      }

      return displayLocation;
    }
  }
};
</script>

<style scoped>
/deep/ th i {
  margin-left: 7px;
}

/deep/ th.active {
  color: #e63f5b !important;
}

/deep/ th.active i {
  color: #e63f5b !important;
}
</style>
