const spiderfierOptions = {
  legWeight: 3,
  keepSpiderfied: true,
  spiderfiedShadowColor: false
};

export function createSpiderfier(map) {
  const OverlappingMarkerSpiderfier = require("overlapping-marker-spiderfier");
  let spiderfier = new OverlappingMarkerSpiderfier(map, spiderfierOptions);

  /* global google */
  const mti = google.maps.MapTypeId;
  if (spiderfier.legColors.usual && spiderfier.legColors.highlighted) {
    spiderfier.legColors.usual[mti.HYBRID] = spiderfier.legColors.usual[
      mti.SATELLITE
    ] = "#444";
    spiderfier.legColors.usual[mti.TERRAIN] = spiderfier.legColors.usual[
      mti.ROADMAP
    ] = "#444";
    spiderfier.legColors.highlighted[
      mti.HYBRID
    ] = spiderfier.legColors.highlighted[
      mti.SATELLITE
    ] = spiderfier.legColors.highlighted[
      mti.TERRAIN
    ] = spiderfier.legColors.highlighted[mti.ROADMAP] = "#444";
  }

  return spiderfier;
}
