function getImageDimensions(imgUrl) {
  return new Promise(resolve => {
    let img = new Image();
    img.src = imgUrl;
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = () => resolve({ height: 0, width: 0 });
  });
}

function getDimensions(dimensions) {
  if (dimensions.height > 32 || dimensions.width > 32) {
    let height = dimensions.height * (32.0 / dimensions.width);
    let width = 32;

    return { height, width };
  }

  return dimensions;
}

export async function createPin(pin) {
  /* global google */
  let dimensions = getDimensions(await getImageDimensions(pin));
  return {
    url: pin,
    anchor: new google.maps.Point(dimensions.width / 2, dimensions.height),
    scaledSize: new google.maps.Size(dimensions.width, dimensions.height)
  };
}
