<template>
  <v-container>
    <transition name="fade" mode="out-in" appear>
      <div v-if="currMap">
        <map-header />
        <br /><br />
        <editor-map :select-id="selectId" @onAdd="onAdd" @onEdit="onEdit" />
        <br /><br />
        <editor-table
          @onAdd="showAddMarker = true"
          @onCopy="onCopy"
          @onEdit="onEdit"
          @onDelete="onDelete"
          @onSelect="onSelect"
        />
      </div>
    </transition>
    <add-marker-modal
      v-if="showAddMarker"
      :lat="lat"
      :long="long"
      :copy-id="id"
      @close="closeModal"
    />
    <edit-marker-modal
      v-if="showEditMarker"
      :id="id"
      :lat="lat"
      :long="long"
      @close="closeModal"
    />
    <delete-marker-modal v-if="showDeleteMarker" :id="id" @close="closeModal" />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import editorMap from "../components/Map/Editor/Map.vue";
import editorTable from "../components/Map/Editor/Table.vue";
import mapHeader from "../components/Map/Header.vue";
const addMarkerModal = () =>
  import(
    /* webpackChunkName: "addMarkerModal" */ "../components/Marker/AddModal.vue"
  );
const editMarkerModal = () =>
  import(
    /* webpackChunkName: "editMarkerModal" */ "../components/Marker/EditModal.vue"
  );
const deleteMarkerModal = () =>
  import(
    /* webpackChunkName: "deleteMarkerModal" */ "../components/Marker/DeleteModal.vue"
  );

export default {
  name: "MapView",
  components: {
    editorMap,
    editorTable,
    addMarkerModal,
    editMarkerModal,
    mapHeader,
    deleteMarkerModal
  },
  data() {
    return {
      showAddMarker: false,
      showEditMarker: false,
      showDeleteMarker: false,
      id: "",
      lat: null,
      long: null,
      selectId: null
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  watch: {
    $route() {
      this.$store.dispatch("getMapData", this.$route.params["title"]);
    }
  },
  mounted() {
    this.$store.dispatch("getMapData", this.$route.params["title"]);
  },
  methods: {
    onAdd(lat, long) {
      this.lat = lat;
      this.long = long;
      this.showAddMarker = true;
    },

    onEdit(id) {
      this.id = id;
      this.showEditMarker = true;
    },

    onCopy(id) {
      this.id = id;
      this.showAddMarker = true;
    },

    onDelete(id) {
      this.id = id;
      this.showDeleteMarker = true;
    },

    onSelect(id) {
      this.selectId = id;
    },

    closeModal() {
      this.showAddMarker = false;
      this.showEditMarker = false;
      this.showDeleteMarker = false;
      this.id = "";
      this.lat = null;
      this.long = null;
    }
  }
};
</script>
