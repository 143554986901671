<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline logo_text">
              <img
                v-if="currMap.icon.length > 0"
                :src="currMap.icon"
                height="42"
              />
              <i v-else class="fa fa-map-o logo_icon">&nbsp;</i>
              {{ currMap.title }}
            </h3>
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            <small v-if="currMap.markers.length > 0">
              Last Updated On {{ currMap.lastUpdated }}
            </small>
            <small v-else-if="maps.length > 1">
              Add markers to your map by clicking on the map or the
              <i class="fa fa-plus" /> 'add markers' button below.
            </small>
            <v-alert v-else type="info">
              Add markers to your map by clicking on the map or the
              <i class="fa fa-plus" /> 'add markers' button below.
            </v-alert>
          </p>

          <p v-if="currMap.markers.length > 0">
            <small v-if="isPublished || publishedMaps.length > 0">
              Your map will need to be published to view new and updated markers
              and updated map data in the map viewer. This can be done by
              clicking on the
              <i class="fa fa-share-square-o" /> 'publish map' button below or
              by navigating to the <i class="fa fa-share-square-o" /> publish
              page.
            </small>
            <v-alert v-else type="info">
              Your map will need to be published to view new and updated markers
              and updated map data in the map viewer. This can be done by
              clicking on the
              <i class="fa fa-share-square-o" /> 'publish map' button below or
              by navigating to the <i class="fa fa-share-square-o" /> publish
              page.
            </v-alert>
          </p>
        </v-card-text>
        <v-card-actions>
          <edit-modal />
          <delete-modal>
            <v-tooltip slot-scope="{ openDialog }" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click.stop="openDialog" text>
                  <i class="fa fa-trash-o" />
                  <span class="hidden-md-and-down">
                    &nbsp;Delete Map
                  </span>
                </v-btn>
              </template>
              <span>Delete Current Map</span>
            </v-tooltip>
          </delete-modal>
          <published-modal v-if="isPublished" />
          <publish-modal v-if="currMap.markers.length > 0">
            <v-tooltip slot-scope="{ openDialog }" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click.stop="openDialog" text>
                  <i class="fa fa-share-square-o" />
                  <span class="hidden-md-and-down">
                    &nbsp;Publish Map
                  </span>
                </v-btn>
              </template>
              <span>Publish Current Map Meta Data And Markers</span>
            </v-tooltip>
          </publish-modal>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex";
const publishedModal = () =>
  import(/* webpackChunkName: "mapModal" */ "./PublishedModal.vue");
const editModal = () =>
  import(/* webpackChunkName: "editModal" */ "./EditModal.vue");
const deleteModal = () =>
  import(/* webpackChunkName: "deleteModal" */ "./DeleteModal.vue");
const publishModal = () =>
  import(/* webpackChunkName: "publishModal" */ "./PublishModal.vue");

export default {
  name: "MapHeader",
  components: {
    publishedModal,
    editModal,
    deleteModal,
    publishModal
  },
  computed: {
    ...mapGetters(["currMap", "maps", "publishedMaps"]),
    isPublished() {
      return this.currMap.publishedDate !== "nodate";
    }
  }
};
</script>
